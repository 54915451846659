﻿@import "../ui-variables";

i[class*="win-icon-"] {
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: middle;
    overflow: hidden;
}

i[class*="win-icon-"]:before {
    display: block;
    width: 1em;
    height: 1em;
    font-family: "Symbols";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
}

.win-icon {
    .icon-mixin(@icons);
}

.icon-mixin(@list) {

    .loop(@i) when (@i > 0) {
        .action(@i);
        .loop(@i - 1);
    }

    .action(@index) {
        @key: extract(extract(@list, @index), 1);
        @value: extract(extract(@list, @index), 2);

        &-@{key}:before {
            content: @value;
        }
    }
    // start the loop
    .loop(length(@list));
}




i.win-button-icon,
a.win-button-icon {
    padding: 5px;
    box-sizing: content-box;
}

i.win-button-icon:hover,
a.win-button-icon:hover {
    background-color: rgba(0, 0, 0, 0.19);
}

i.win-button-icon:active,
a.win-button-icon:hover {
    background-color: rgba(0, 0, 0, 0.28);
}


.win-icon-sm {
    font-size: 12px;
}

.win-icon-md {
    font-size: 32px;
}

.win-icon-lg {
    font-size: 64px;
}